<template>
  <div class="card shadow-sm bg-white border p-8">
   <div class="my-6 text-start">
    <h3 class="font-bold">Editar Tipo de Produto</h3>
    <span class="text-sm text-gray-500"
      >Verifique as informações abaixo e clique no botão <b>Salvar</b> para
          confirmar as modificações feitas
    </span>
  </div>

  <div class="flex flex-row mt-5 items-center">
    <div class="w-full p-2">
    <Input 
      v-model="tipo.nome"
      type="default"
      label="Tipo de Produto"
      placeholder="Tipo de Produto"
    />
    </div>
    <div class="flex justify-center">
    <Button @click="edit" color="primary" text="Salvar"  />
  </div>
  </div>
  
  
 </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref } from "vue";
import { GET, PUT } from "../../services/api";
import { isEmpty } from "../../services/validate";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "EditarTiposProdutos",
  components: {
    Input,
    Button,
  },
  data()
  {
    return{
      isMobile: false
    }
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },

  setup() {
  const tipo = ref({
      id: "",
      nome : ""
    })
    const loader = inject("loading");
    const alert = inject("alert");
    const router = useRouter();
    const route = useRoute();
    const modal = inject("modal")

    const edit = async () => {
      delete tipo.value.id

      const validate = isEmpty(tipo.value);
      if (validate) {
        alert.open("Atenção!", validate, "warning");
      } else {
        modal.open("Atenção", "Deseja realmente editar esse tipo produto?", "warning", "Sim" , async () => {
          try {
          await PUT(`tipo_produto/${route.params.id}`, tipo.value)
          loader.open();
          alert.open(
            "Sucesso!",
            `Produto editado com sucesso!`,
            "success"
          );
          loader.close();
          router.push("/tipo_produto");
        } catch (e) {
          loader.close();
          alert.open("Atenção!", e.message, "warning");
        }
        },
        modal.close())
      }
      
    };

    onMounted( async () => {
      loader.open();


      tipo.value = await GET(`tipo_produto/${route.params.id}`);

      
      loader.close();
    });

    return {
      tipo,
      edit
    };
  },
};
</script>

<style>

.btn{
    width: 100%;
    align-items: center;
    justify-content: center;
  }
</style>
